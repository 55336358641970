<template>
  <v-container>
    <v-row>
      <v-col sm="12">
        <AccountCreationForm
          :title="title"
          :editPage="editPage"
          :selectedBusiness="selectedBusiness"
        ></AccountCreationForm>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { authMethods, authComputed } from '@state/helpers'
import Business from '@classes/Business'
import AccountCreationForm from '@components/agriculture-main/business/user/accountCreationForm'
export default {
  page: {
    title: 'Account',
  },
  components: {
    AccountCreationForm,
  },
  data() {
    return {
      title: 'AccountCreation',
      editPage: false,
      selectedBusiness: new Business(),
    }
  },
  created() {
    if (this.currentUser && this.currentUser.UserBusinessUser.length > 0) {
      if (this.currentUser.UserBusinessUser.length == 1) {
        var businessId = this.currentUser.UserBusinessUser[0].BusinessID
        //if they only have one business we load it
        this.$router.push({
          path: `/userBusiness/${businessId}`,
        })
      } else {
        this.$router.push({
          path: `/userBusiness`,
        })
      }
    }
  },
  computed: {
    ...authComputed,
  },
  methods: {},
  watch: {},
}
</script>
<style scoped></style>
